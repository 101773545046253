// import carrinhoModel from '../entities/CarrinhoModel';

let session = sessionStorage
let local = localStorage

//---------- Cardapio ----------
export const setCardapioStorage = cardapio => {
    session.setItem("cardapioCF", convertToString(cardapio))
}

export const getCardapioStorage = () => {
    try {
        let cardapioString = session.getItem("cardapioCF")
        if (cardapioString) {
            return JSON.parse(cardapioString)
        }

        throw new Error("CardapioStorage is empty")
    } catch (error) {
        return {}
    }
}

export const deleteCardapioStorage = () => {
    session.removeItem("cardapioCF")
}
//---------- FIM Cardapio ----------

//---------- DATA Crdápio Cardapio ----------
export const deleteCardapioDateStorage = () => {
    local.removeItem("dateCardapioCF")
}
export const setCardapioDateStorage = () => {
    session.setItem("dateCardapioCF")
}
//---------- FIM Crdápio Cardapio ----------

//---------- Carrinho  ----------
export const setCarrinhoStorage = carrinho => {
    local.setItem("car", cripto(convertToString(carrinho)))
    removeOldCarrinhoCache()
}

const carrinho = {
    valorMinimoRetiradaNoLocal: null,
    valorMinimoPedido: null,
    valorDesconto: null,
    percentualDesconto: null,
    minimoEntregaGratis: null,
    maximoPedidoComDesconto: null,
    maximoDesconto: null,
    cupomDesconto: null,
    pedido: {
        entregaImediata: false,
        formaPagamento: {},
        itens: [],
        taxaEntrega: null,
        tokenCartaoCredito: null,
        troco: null,
        valorDesconto: null,
        valorEntrega: null,
        valorTotal: 0,
        token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
    },
}

export const getCarrinhoStorage = () => {
    try {
        let carrinhoString = local.getItem("car")
        if (carrinhoString) {
            return descripto(carrinhoString)
        }

        throw new Error("CarrinhoStorage is empty")
    } catch (error) {
        return carrinho
    }
}

export const deleteCarrinhoStorage = () => {
    local.removeItem("car")
    removeOldCarrinhoCache()
}
//---------- FIM Carrinho  ----------

//---------- EnderecoAtual ----------
export const setEnderecoAtualStorage = enderecoAtual => {
    session.setItem("enderecoAtualCF", convertToString(enderecoAtual))
}

export const getEnderecoAtualStorage = () => {
    try {
        let endereco = session.getItem("enderecoAtualCF")
        if (endereco) {
            return JSON.parse(endereco)
        }

        throw new Error("EnderecoStorage is Empty")
    } catch (error) {
        return {}
    }
}

export const deleteEnderecoAtualStorage = () => {
    session.removeItem("enderecoAtualCF")
}
//---------- FIM EnderecoAtual ----------

//---------- EstabelecimentoAtual ----------
export const setEstabelecimentoAtualStorage = estabelecimentoAtual => {
    local.setItem("esta", cripto(convertToString(estabelecimentoAtual)))
    deleteOldEstabelecimentoAtualStorage()
}

export const getEstabelecimentoAtualStorage = () => {
    try {
        let retorno = descripto(local.getItem("esta"))
        if (!retorno) {
            return retorno
        }
        throw new Error("EstabelecimentoAtual empty")
    } catch (error) {
        return {}
    }

    // if (!retorno) {
    //     try {
    //         retorno = JSON.parse(local.getItem("estabelecimentoAtualCF"))
    //     } catch (error) {
    //         retorno = {}
    //     }
    // }
}

export const deleteEstabelecimentoAtualStorage = () => {
    local.removeItem("esta")
    deleteOldEstabelecimentoAtualStorage()
}

function deleteOldEstabelecimentoAtualStorage() {
    local.removeItem("estabelecimentoAtualCF")
}
//---------- FIM EstabelecimentoAtual ----------

//----------- Usuario Logado ----------
export const setUsuarioLogadoStorage = usuarioLogado => {
    local.setItem("use", cripto(convertToString(usuarioLogado)))
    deleteOldUsuarioLogadoStorage()
}

//o decripto faz o JSON.parce
export const getUsuarioLogadoStorage = () => {
    try {
        var usuario = descripto(local.getItem("use"))
        if (usuario) {
            return usuario
        }

        throw new Error("UsuarioStorage empty")
    } catch (error) {
        return null
    }
}

function getOldUsuarioLogadoStorage() {
    try {
        return JSON.parse(local.getItem("usuarioCF"))
    } catch (error) {
        return null
    }
}
export const deleteUsuarioLogadoStorage = () => {
    local.removeItem("use")
    deleteOldUsuarioLogadoStorage()
}

function deleteOldUsuarioLogadoStorage() {
    local.removeItem("usuarioCF")
}

export const setDateUsuarioStorage = dado => {
    local.setItem("dateUsuarioCF", dado)
}

export const deleteDateUsuarioStorage = () => {
    local.removeItem("dateUsuarioCF")
}
//---------- FIM Usuario Logado ----------

//---------- AplicativoDados ----------
export const setAppDadosStorage = appDados => {
    local.setItem("app", cripto(convertToString(appDados)))
    setAppDadosDateStorage()
    removeOldAppDadosCache()
}
function setAppDadosDateStorage() {
    local.setItem("app_dados_date", new Date())
}

function deleteAppDadosDateStorage() {
    local.removeItem("app_dados_date")
}

export const getAppDadosStorage = () => {
    try {
        let appDadosString = local.getItem("app")
        if (appDadosString) {
            return descripto(appDadosString)
        }
        throw new Error("EmptyStorage app")
    } catch (error) {
        return null
    }
}
function getOldAppDadosStorage() {
    try {
        return JSON.parse(local.getItem("aplicativoCF"))
    } catch (error) {
        return {}
    }
}
export const deleteAppDadosStorage = () => {
    local.removeItem("app")
    deleteAppDadosDateStorage()
    removeOldAppDadosCache()
}
//---------- FIM AplicativoDados----------

//----------  UsuarioIzza ----------
export const setUsuarioIzzaStorage = usuario => {
    local.setItem("usuarioIzzaCF", JSON.stringify(usuario))
}

export const getUsuarioIzzaStorage = () => {
    try {
        let usuarioIzza = local.getItem("usuarioIzzaCF")
        if (usuarioIzza) {
            return JSON.parse(usuarioIzza)
        }
        throw new Error("UsuarioIzzaStorage is empty")
    } catch (error) {
        return null
    }
}
//---------- FIM UsuarioIzza ----------

//---------- UsuarioMesa Date----------

export const setUsuarioMesaDateStorage = usuarioMesaDate => {
    local.setItem("usuarioPedidoMesaCF_Date", usuarioMesaDate)
}

export const getUsuarioMesaDateStorage = usuarioMesaDate => {
    return local.getItem("usuarioPedidoMesaCF_Date")
}

export const deleteUsuarioMesaDateStorage = usuarioMesaDate => {
    local.setItem("usuarioPedidoMesaCF_Date", usuarioMesaDate)
}

//---------- FIM UsuarioMesa Date----------

//---------- UsuarioMesa ----------
export const setUsuarioMesaStorage = usuarioMesa => {
    local.setItem("usuarioPedidoMesaCF", JSON.stringify(usuarioMesa))
}

export const getUsuarioMesaStorage = () => {
    let usuarioMesa = local.getItem("usuarioPedidoMesaCF")
    return JSON.parse(usuarioMesa)
}
export const deleteUsuarioMesaStorage = () => {
    local.removeItem("usuarioPedidoMesaCF")
}
//---------- FIM UsuarioMesa ----------

//---------- Token Firebase ----------
export const setTokenFirebaseStorage = token => {
    local.setItem("tokenNotificationFirebase", token)
}

export const deleteTokenFirebaseStorage = () => {
    local.removeItem("tokenNotificationFirebase")
}

export const getTokenFirebaseStorage = () => {
    return local.getItem("tokenNotificationFirebase")
}
//---------- FIM Token Firebase ----------

//---------- Bairro Específico ----------
export const deleteBairroEspecificoStorage = () => {
    local.removeItem("tokenNotificationFirebase")
}
//---------- FIM Bairro Específico ----------

//---------- Versão App ----------
export const setVersionAppStorage = versao => {
    local.setItem("versao", versao)
}
//---------- FIM Versão App ----------

//---------- Taxa Entrega Estabelecimentos ----------
export const setTaxaEntregaEstabelecimentosStorage = dados => {
    local.setItem("estabelecimentosTaxasEnderecos", JSON.stringify(dados))
}

export const getTaxaEntregaEstabelecimentosStorage = () => {
    return JSON.parse(local.getItem("estabelecimentosTaxasEnderecos"))
}

export const deleteTaxaEntregaEstabelecimentosStorage = () => {
    local.removeItem("estabelecimentosTaxasEnderecos")
}
//---------- FIM Entrega Estabelecimentos ----------

//---------- Último Pedido  ----------
export const setUltimoPedidoStorage = pedido => {
    local.setItem("ultimoPedidoCF", JSON.stringify(pedido))
}
export const getUltimoPedidoStorage = () => {
    return JSON.parse(local.getItem("ultimoPedidoCF"))
}

export const deleteUltimoPedidoStorage = () => {
    local.removeItem("ultimoPedidoCF")
}
//---------- FIM Último Pedido  ----------

//---------- Pedido Cancelado  ----------
export const setPedidoCanceladoBakupStorage = pedido => {
    local.setItem("PedidoCanceladoBackupCF", JSON.stringify(pedido))
}

export const getPedidoCanceladoBakupStorage = () => {
    return JSON.parse(local.getItem("PedidoCanceladoBackupCF"))
}

export const deletePedidoCanceladoBakupStorage = () => {
    local.removeItem("PedidoCanceladoBackupCF")
}
//---------- FIM Pedido Cancelado  ----------

//---------- Sem Ultimo Pedido  ----------
export const setSemUltimoPedidoStorage = valor => {
    local.setItem("semUltimoPedido", valor)
}

export const getSemUltimoPedidoStorage = () => {
    return local.getItem("semUltimoPedido")
}

export const deleteSemUltimoPedidoStorage = () => {
    local.removeItem("semUltimoPedido")
}
//---------- FIM Sem Ultimo Pedido  ----------

//---------- Sem Ultimo Pedido  ----------
export const setCupomLinkStorage = link => {
    local.setItem("cupomLink", JSON.stringify(link))
}

export const getCupomLinkStorage = () => {
    return JSON.parse(local.getItem("cupomLink"))
}

export const deleteCupomLinkStorage = () => {
    local.removeItem("cupomLink")
}
//---------- FIM Sem Ultimo Pedido  ----------

//---------- Sem Ultimo Pedido  ----------
export const setHistoricoPedidosStorage = historico => {
    local.setItem("historicoPedidosCF", JSON.stringify(historico))
}

export const getHistoricoPedidosStorage = () => {
    return JSON.parse(local.getItem("historicoPedidosCF"))
}

export const deleteHistoricoPedidosStorage = () => {
    local.removeItem("historicoPedidosCF")
}
//---------- FIM Sem Ultimo Pedido  ----------

//---------- Sem Ultimo Pedido  ----------
export const setTipoLoginStorage = tipoLogin => {
    local.setItem("tipoLoginCF", tipoLogin)
}

export const getTipoLoginStorage = () => {
    return local.getItem("tipoLoginCF")
}
export const deleteTipoLoginStorage = () => {
    local.removeItem("tipoLoginCF")
}

//---------- FIM Sem Ultimo Pedido  ----------

//---------- ONG  ----------
export const setOngIdStorage = ongId => {
    local.setItem("ongId", ongId)
}

export const getOngIdStorage = () => {
    return local.getItem("ongId")
}

export const setOngNameStorage = ongName => {
    local.setItem("ongName", ongName)
}

export const getOngNameStorage = () => {
    return local.getItem("ongName")
}

//---------- FIM ONG  ----------

//---------- Logout ----------
export const logoutStorage = () => {
    deletePedidoCanceladoBakupStorage()
    deleteUsuarioMesaStorage()
    deleteUltimoPedidoStorage()
    deleteCardapioDateStorage()
    deleteUsuarioMesaDateStorage()
    deleteDateUsuarioStorage()
}
//---------- FIM Logout ----------

export const deleteAllStorage = () => {
    local.clear()
    session.clear()
}

function removeOldCarrinhoCache() {
    local.removeItem("carrinhoCF")
}

function removeOldAppDadosCache() {
    local.removeItem("aplicativoCF")
}

//---------- Demais Itens ----------
//---------- FIM Demais Itens ----------

//----------------- aux functions -----------------
function convertToString(jsonObject) {
    if (!jsonObject) {
        return ""
    }
    return JSON.stringify(jsonObject)
}

function isAppTest() {
    return !!(local.getItem("teste") || window.location.hostname.includes("localhost"))
}

function cripto(stringNew) {
    try {
        if (isAppTest()) {
            return stringNew
        }
        return btoa(stringNew)
    } catch (error) {}
}

function descripto(stringNew) {
    try {
        if (isAppTest()) {
            return JSON.parse(stringNew)
        }
        return JSON.parse(atob(stringNew))
    } catch (error) {
        return null
    }
}
//    return (
//       <StoreManagerContext.Provider
//          value={{
//             setCardapioStorage,
//             setCarrinhoStorage,
//             deleteCarrinhoStorage,
//             deleteCardapioStorage,

//             deleteAllStorage,
//             setAppDadosStorage,
//             getAppDadosStorage,
//             deleteAppDadosStorage,

//             setEnderecoAtualStorage,
//             deleteEnderecoAtualStorage,

//             setEstabelecimentoAtualStorage,
//             deleteEstabelecimentoAtualStorage,

//             setUsuarioLogadoStorage,
//             deleteUsuarioLogadoStorage,
//             getUsuarioLogadoStorage,

//             setUsuarioIzzaStorage,
//             getUsuarioIzzaStorage,

//             getUsuarioMesaStorage,
//             setUsuarioMesaStorage,

//             setUsuarioMesaDateStorage,
//             getUsuarioMesaDateStorage,
//             deleteUsuarioMesaDateStorage,

//             setDateUsuarioStorage,
//             deleteDateUsuarioStorage,

//             getCarrinhoStorage,
//             getEnderecoAtualStorage,

//             getCardapioStorage,
//             deleteCardapioDateStorage,
//             setCardapioDateStorage,

//             getTokenFirebaseStorage,
//             deleteTokenFirebaseStorage,
//             setTokenFirebaseStorage,

//             deleteBairroEspecificoStorage,

//             setVersionAppStorage,

//             deleteTaxaEntregaEstabelecimentosStorage,
//             setTaxaEntregaEstabelecimentosStorage,
//             getTaxaEntregaEstabelecimentosStorage,

//             setUltimoPedidoStorage,
//             getUltimoPedidoStorage,
//             deleteUltimoPedidoStorage,

//             setSemUltimoPedidoStorage,
//             deleteSemUltimoPedidoStorage,
//             getSemUltimoPedidoStorage,

//             setPedidoCanceladoBakupStorage,
//             getPedidoCanceladoBakupStorage,
//             deletePedidoCanceladoBakupStorage,

//             deleteCupomLinkStorage,
//             getCupomLinkStorage,
//             setCupomLinkStorage,

//             setHistoricoPedidosStorage,
//             getHistoricoPedidosStorage,
//             deleteHistoricoPedidosStorage,

//             setTipoLoginStorage,
//             getTipoLoginStorage,
//             deleteTipoLoginStorage,

//             logoutStorage,

//             setOngIdStorage,
//             getOngIdStorage,
//             setOngNameStorage,
//             getOngNameStorage,

//          }}
//       >
//          {children}
//       </StoreManagerContext.Provider>
//    )
// }
