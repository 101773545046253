import { useHistory } from "react-router-dom"

export default function useCustomHistory() {
    const history = useHistory()

    // TODO: Alterar para receber searchParams nos parâmetros
    const pushWithSearchParams = (baseURL, newParams) => {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(newParams)

        url.pathname = baseURL

        params.forEach((value, key) => {
            if (value === "null" || value === "undefined") url.searchParams.delete(key)
            else url.searchParams.set(key, value)
        })

        history.push(url.pathname + url.search)
    }

    // Por enquanto, usado somente para desconectar da mesa
    const removeSearchParam = param => {
        const url = new URL(window.location.href)
        url.searchParams.delete(param)

        window.location.href = url.href
    }

    return { ...history, push: pushWithSearchParams, removeSearchParam }
}
