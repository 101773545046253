import { makeStyles } from "@material-ui/core/styles"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { useSnackbar } from "notistack"
import { useContext, useState } from "react"
import SiteContext from "../../contexts/SiteContext"

import IconButton from "@material-ui/core/IconButton"

import TextField from "@material-ui/core/TextField"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

import { Autenticar_V2, RegistrarLogErro } from "../../services/functions"
import "./styles.css"

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    rootAlert: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    root: {
        width: "100%",
        margin: "1em 0",
    },
    pos: {
        marginBottom: 12,
    },
}))

export default function VerificarTelefone(props) {
    const { setVerificarTelefone, verificarTelefone, telefone, loginUsuarioSenha } = props
    const { aplicativoDados, setLoading } = useContext(SiteContext)

    const [codigo, setCodigo] = useState("")

    const { enqueueSnackbar } = useSnackbar()

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    async function verificarTokenLoginTelefone(codigo) {
        setLoading(true)
        const data = {
            idCliente: -1,
            appName: aplicativoDados.appNome,
            telefone: telefone,
            codigoAcesso: codigo,
        }
        try {
            const response = await Autenticar_V2(data, aplicativoDados)
            setCodigo("")
            setVerificarTelefone(false)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
            } else {
                loginUsuarioSenha({
                    usuario: response[0].email,
                    hashSenha: response[0].hashSenha,
                    formaLogin: "loginTelefone",
                })
                return true
            }
        } catch (error) {
            RegistrarLogErro(error, "verificarTokenLoginTelefone", data)
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
        setLoading(false)
    }

    return (
        <Dialog
            open={verificarTelefone}
            aria-labelledby="esqueceuSenha"
            onClose={() => {
                setCodigo("")
                setVerificarTelefone(false)
            }}
        >
            <img src={aplicativoDados.urlLogo} className="imgModal" />

            <DialogTitle id="verificarTelefone">
                <IconButton
                    className="setaVoltarModal"
                    onClick={() => {
                        setCodigo("")
                        setVerificarTelefone(false)
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
                Verifique seu telefone
            </DialogTitle>

            <DialogContent>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={codigo}
                    onChange={e => {
                        if (e.target.value.length === 4) {
                            verificarTokenLoginTelefone(e.target.value)
                        }
                        if (e.target.value.length <= 4) {
                            setCodigo(e.target.value)
                        }
                    }}
                    id="CodigoVerificacao"
                    label="Codigo de Verificação"
                    name="CodigoVerificacao"
                    type="number"
                    autoFocus
                />
                {/* <Grid container>
            <Grid item xs>
              <Link to='#' onClick={() => { setTelaCodigo(false) }} variant="body2" style={{ "color": `${aplicativoDados.corSitePrimaria}` }}>
                {telefone} Editar telefone
              </Link>
            </Grid>
          </Grid> */}
            </DialogContent>
        </Dialog>
    )
}
