import { createContext, useContext, useState } from "react"
import idiomas from "../assets/idiomas.json"

export const TextosContext = createContext(null)

export function TextosContextProvider(props) {
    const [idioma, setIdioma] = useState("br")

    const textos = idiomas[idioma]

    return (
        <TextosContext.Provider
            value={{
                idioma,
                setIdioma,
                textos,
            }}
        >
            {props.children}
        </TextosContext.Provider>
    )
}

export const useTextos = () => useContext(TextosContext).textos
