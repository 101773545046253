import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    makeStyles,
    Typography,
} from "@material-ui/core"
import { ArrowForwardIos as ArrowForwardIosIcon } from "@material-ui/icons"
import { createContext, useContext, useState } from "react"
import SiteContext from "../../contexts/SiteContext"
import { TextosContext } from "../../contexts/TextosContext"
import Login from "../../pages/Login"

const useStyles = makeStyles(theme => ({
    dialogLogin: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "16px",
        minWidth: "480px",
        [theme.breakpoints.down("xs")]: {
            minWidth: "0px",
        },
        margin: "16px 0",
        padding: "0",
        overflow: "hidden",
    },
    mensagem: {
        maxWidth: "80%",
        margin: "4px",
    },
}))

export const LoginDialogContext = createContext(null)

export function LoginDialogContextProvider({ children }) {
    const [abrirDialogLogin, setAbrirDialogLogin] = useState(false)
    const [mensagemDialogLogin, setMensagemDialogLogin] = useState("")

    return (
        <LoginDialogContext.Provider
            value={{
                abrirDialogLogin,
                setAbrirDialogLogin,
                mensagemDialogLogin,
                setMensagemDialogLogin,
            }}
        >
            {children}
        </LoginDialogContext.Provider>
    )
}

export default function DialogLogin() {
    const classes = useStyles()

    const { abrirDialogLogin, setAbrirDialogLogin, mensagemDialogLogin, setMensagemDialogLogin } =
        useContext(LoginDialogContext)
    const { aplicativoDados } = useContext(SiteContext)
    const { textos } = useContext(TextosContext)

    const handleClose = () => {
        setAbrirDialogLogin(false)
        setMensagemDialogLogin("")
    }

    return (
        <Dialog open={abrirDialogLogin} maxWidth="sm" onClose={handleClose}>
            <img src={aplicativoDados?.urlLogo} className="imgModal" />
            <DialogTitle>
                <IconButton className="setaVoltarModal" onClick={handleClose}>
                    <ArrowForwardIosIcon />
                </IconButton>
                {textos.autenticacao}
            </DialogTitle>
            <DialogContent className={classes.dialogLogin}>
                {mensagemDialogLogin.split(" - ").map(fragmentoDaMensagem => (
                    <Typography className={classes.mensagem}>{fragmentoDaMensagem}</Typography>
                ))}
                <Login telaCardapio isEnviarPedido />
            </DialogContent>
        </Dialog>
    )
}
