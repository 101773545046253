import { useSnackbar } from "notistack"
import React, { useContext } from "react"
import useParametros from "./ParametrosContext"
import SiteContext from "./SiteContext"

import {
    CadastrarEnderecoCliente,
    ObterCardapioCompletoV1,
    ObterCardapioPedidoMesa,
    ObterCupomDescontoCompleto,
    RegistrarLogErro,
    removerProdutosCategoria,
} from "../services/functions"

export const CalculosContext = React.createContext(null)

export function CalculosContextProvider(props) {
    const { children } = props

    const {
        aplicativoDados,

        estabelecimentoAtual,
        usuarioLogado,
        setUsuarioLogado,

        carrinho,
        setCarrinho,

        enderecoAtual,
        setEnderecoAtual,

        cardapio,
        setCardapio,
    } = useContext(SiteContext)

    const parametros = useParametros()

    const { enqueueSnackbar } = useSnackbar()
    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const usarCarrinho = () => {
        return JSON.parse(JSON.stringify(carrinho))
    }
    const usarEnderecoAtual = () => {
        return JSON.parse(JSON.stringify(enderecoAtual))
    }
    const usarCardapio = () => {
        return JSON.parse(JSON.stringify(cardapio))
    }
    const usarEstabelecimentoAtual = () => {
        return JSON.parse(JSON.stringify(estabelecimentoAtual))
    }
    const usarUsuarioLogado = () => {
        return JSON.parse(JSON.stringify(usuarioLogado))
    }

    async function calcularValorTotal({
        carrinho = usarCarrinho(),
        enderecoAtual = usarEnderecoAtual(),
        cardapio = usarCardapio(),
        estabelecimentoAtual = usarEstabelecimentoAtual(),
    } = {}) {
        try {
            console.log(">> calcularValorTotal", carrinho, cardapio, enderecoAtual)

            cardapio.cupomDesconto = carrinho.cupomDesconto
            if (cardapio.cupomDesconto) {
                cardapio.percentualDesconto = cardapio.cupomDesconto?.valorDescontoPercentual
                cardapio.valorDesconto = cardapio.cupomDesconto?.valorDesconto
            } else {
                cardapio.percentualDesconto = carrinho.percentualDesconto
                cardapio.valorDesconto = cardapio.valorDesconto
            }

            const isRetiradaNoLocal =
                enderecoAtual?.id === "retirada" || enderecoAtual.logradouro === "Buscar no local"
            const isPedidoDeMesa = carrinho.pedido.tipoPedido === 2

            const minimoParaEntregaGratis = Math.min(
                cardapio.minimoEntregaGratis ?? 999999999.9,
                enderecoAtual.minimoEntregaGratis ?? 999999999.9
            )
            carrinho.minimoEntregaGratis = minimoParaEntregaGratis

            let valorProdutosPromocionais = 0
            const valorTotalItens = carrinho.pedido.itens?.reduce((total, item) => {
                if (item.produto.promocional) {
                    valorProdutosPromocionais += item.valorProdutoHistorico
                }

                return total + item.valorProdutoHistorico
            }, 0)

            var valorFinalProdutos = valorTotalItens

            carrinho.percentualDescontoValor = 0.0

            if (cardapio.percentualDesconto && !cardapio.valorDesconto) {
                // DESCONTO PERCENTUAL (Apenas em produtos nao promocionais)
                const descontoPercentual = Math.min(
                    ((valorTotalItens - valorProdutosPromocionais) * cardapio.percentualDesconto) /
                        100,
                    carrinho.cupomDesconto?.maximoDesconto ?? cardapio.maximoDesconto ?? Infinity
                )

                carrinho.percentualDescontoValor = descontoPercentual
                carrinho.pedido.valorDesconto = descontoPercentual

                valorFinalProdutos -= descontoPercentual
            } else {
                //DESCONTO FIXO (Apenas em produtos nao promocionais)
                const descontoFixo = Math.min(
                    Math.max(carrinho.pedido.valorDesconto ?? 0, cardapio.valorDesconto ?? 0),
                    cardapio.maximoDesconto ?? Infinity
                )

                valorFinalProdutos -= descontoFixo
            }

            if (carrinho.pedido?.formaPagamento?.id) {
                // NÃO é calculado sobre taxa de entrega
                carrinho.pedido.descontoFormaPagamento = carrinho.pedido.formaPagamento.desconto
                    ? (valorFinalProdutos * carrinho.pedido.formaPagamento.desconto) / 100
                    : 0

                valorFinalProdutos -= carrinho.pedido.descontoFormaPagamento
            }

            valorFinalProdutos = Math.max(valorFinalProdutos, 0)

            let taxaDeEntrega = 0

            if (enderecoAtual && typeof enderecoAtual.taxaEntrega === "number")
                carrinho.pedido.valorEntrega = enderecoAtual.taxaEntrega

            const isTaxaGratis =
                (minimoParaEntregaGratis && valorFinalProdutos >= minimoParaEntregaGratis) ||
                carrinho.cupomDesconto?.freteGratis

            if (!isRetiradaNoLocal && !isPedidoDeMesa && !isTaxaGratis) {
                // NAO FOR RETIRADA NO LOCAL, PEDIDO MESA NEM TAXA GRÁTIS
                taxaDeEntrega = carrinho.pedido.valorEntrega
                    ? carrinho.pedido.valorEntrega
                    : enderecoAtual.taxaEntrega
                    ? enderecoAtual.taxaEntrega
                    : 0

                taxaDeEntrega = Math.max(taxaDeEntrega, 0)
            }

            carrinho.pedido.valorEntrega = taxaDeEntrega
            carrinho.pedido.taxaEntrega = taxaDeEntrega

            const taxaAdicional = parametros.PUBLIC_TAXA_ADICIONAL
            carrinho.pedido.taxaAdicional = taxaAdicional

            carrinho.pedido.valorTotal =
                valorFinalProdutos + taxaDeEntrega + (taxaAdicional ? Number(taxaAdicional) : 0)

            if (!carrinho.cupomCliente) delete carrinho.cupomCliente

            setCarrinho(carrinho)
            setCardapio(cardapio)
            setEnderecoAtual(enderecoAtual)
        } catch (error) {
            RegistrarLogErro(error, "calcularValorTotal", {
                carrinho,
                enderecoAtual,
                cardapio,
                estabelecimentoAtual,
            })
            alertStart(
                "Procure os desenvolvedores! Erro calcularValorTotal: " + error.message,
                "error"
            )
            console.error(error)
        }
    }

    const ObterCardapioCalculo = async (props = {}) => {
        const {
            enderecoAtual = usarEnderecoAtual(),
            cardapio = usarCardapio(),
            usuarioLogado = usarUsuarioLogado(),
            estabelecimentoAtual = usarEstabelecimentoAtual(),
            forcarAtualicao = false,
        } = props

        try {
            const dataUltimoCardapio = Number(localStorage.getItem("dateCardapioCF"))
            const dataAgora = new Date().getTime()

            if (
                forcarAtualicao ||
                !cardapio?.id ||
                cardapio.idEstabelecimento !== estabelecimentoAtual?.id ||
                dataUltimoCardapio + 1 * 60 * 1000 < dataAgora
            ) {
                let data = {
                    idCliente: usuarioLogado?.cliente?.id,
                    idEstabelecimento: estabelecimentoAtual?.id,
                    token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
                }

                if (enderecoAtual.ativo) {
                    data.idEndereco = enderecoAtual?.id
                }

                const mesaLogada = !!JSON.parse(localStorage.getItem("usuarioPedidoMesaCF"))?.logado
                const response = mesaLogada
                    ? await ObterCardapioPedidoMesa(data, aplicativoDados)
                    : await ObterCardapioCompletoV1(data, aplicativoDados)
                console.log("data ObterCardapio", response)

                if (response.retornoErro) {
                    RegistrarLogErro(response, "verificarCardapio - retornoErro", props)
                    //algum erro verificarCardapio
                } else {
                    response.idEstabelecimento = estabelecimentoAtual?.id
                    props.cardapio = response
                    retornoVerificarCardapio(props)
                }
            } else {
                props.cardapio = cardapio
                retornoVerificarCardapio(props)
            }
        } catch (error) {
            RegistrarLogErro(error, "verificarCardapio", props) //, crypto.randomUUID())
            alertStart(
                "Procure os desenvolvedores! Erro verificarCardapio: " + error.message,
                "error"
            )
        }
    }

    const retornoVerificarCardapio = async props => {
        var {
            enderecoAtual = usarEnderecoAtual(),
            cardapio = usarCardapio(),
            usuarioLogado = usarUsuarioLogado(),
            estabelecimentoAtual = usarEstabelecimentoAtual(),
            carrinho = usarCarrinho(),
        } = props

        try {
            const isRetiradaNoLocal = enderecoAtual?.id === "retirada" || enderecoAtual?.id === -1

            console.log("retornoVerificarCardapio", cardapio)
            if (!cardapio.codErro > 0) {
                const retorno = cardapio
                let endereco = enderecoAtual
                if (
                    retorno.enderecos != null &&
                    retorno.enderecos.length > 0 &&
                    !isRetiradaNoLocal
                ) {
                    retorno.enderecos.forEach(newEnd => {
                        if (endereco.id == newEnd.id) {
                            endereco = newEnd
                        }
                    })
                }

                if (!carrinho || typeof carrinho != "object") {
                    carrinho = {}
                }

                if (!carrinho?.pedido) {
                    carrinho.pedido = {}
                }

                console.log("enderecoendereco1", endereco)

                if (
                    cardapio?.cupomDesconto &&
                    !retorno?.cupomDesconto &&
                    !retorno?.idCartelaCompleta
                ) {
                    retorno.cupomDesconto = cardapio?.cupomDesconto

                    if (retorno.cupomDesconto?.valorDesconto) {
                        retorno.valorDesconto = retorno.cupomDesconto.valorDesconto
                    }

                    if (retorno.cupomDesconto?.valorDescontoPercentual) {
                        retorno.percentualDesconto = retorno.cupomDesconto.valorDescontoPercentual
                    }
                }

                if (cardapio && !cardapio.cupomDesconto && carrinho?.cupomDesconto) {
                    carrinho.cupomDesconto = null
                }

                //INICIO ATUALIZANDO CARRINHO
                carrinho.valorMinimoPedido = retorno.valorMinimoPedido
                carrinho.valorMinimoRetiradaNoLocal = retorno.valorMinimoRetiradaNoLocal
                if (!carrinho.cupomDesconto?.codigoCupom) {
                    carrinho.valorDesconto = retorno.valorDesconto
                    carrinho.percentualDesconto = retorno.percentualDesconto
                }
                carrinho.minimoEntregaGratis = enderecoAtual.minimoEntregaGratis
                    ? Math.min(retorno.minimoEntregaGratis, enderecoAtual.minimoEntregaGratis)
                    : retorno.minimoEntregaGratis
                carrinho.maximoPedidoComDesconto = retorno.maximoPedidoComDesconto
                carrinho.maximoDesconto = retorno.maximoDesconto
                carrinho.cupomDesconto = carrinho.cupomDesconto ?? retorno.cupomDesconto
                const taxa =
                    retorno.enderecos != null && retorno.enderecos.length > 0 && !isRetiradaNoLocal
                        ? endereco.taxaEntrega
                        : null
                carrinho.valorEntrega = taxa
                carrinho.taxaEntrega = taxa
                //pedido
                carrinho.pedido.taxaEntrega = taxa
                carrinho.pedido.valorEntrega = taxa
                if (carrinho.percentualDesconto) {
                    carrinho.pedido.valorDesconto = carrinho.percentualDescontoValor
                } else {
                    carrinho.pedido.valorDesconto = carrinho.valorDesconto
                }
                //FIM ATUALIZANDO CARRINHO

                // console.log('taxataxa', taxa)

                if (retorno?.cupomDesconto?.freteGratis && taxa >= 0) {
                    //console.log('mudando Taxa ')
                    carrinho.pedido.valorEntrega = 0
                    carrinho.pedido.taxaEntrega = 0
                    if (endereco.bairro) {
                        endereco.taxaEntrega = 0
                    }
                    console.log("enderecoendereco2", endereco)
                } else if (retorno?.enderecos?.length && !isRetiradaNoLocal) {
                    carrinho.pedido.valorEntrega = endereco.taxaEntrega
                    carrinho.pedido.taxaEntrega = endereco.taxaEntrega
                    console.log("enderecoendereco3", endereco)
                }

                ////console.log("atualizarCardapio", retorno)
                if (!usuarioLogado?.logado) {
                    retorno.id = "usuarioDeslogado"
                }

                if (aplicativoDados.buscaProdutosPaginada) {
                    removerProdutosCategoria(props)
                }
                props.cardapio = cardapio

                apartirDe(props)

                props.carrinho = carrinho
                props.estabelecimentoAtual = estabelecimentoAtual

                var attCardapio = retorno
                if (estabelecimentoAtual?.adicionarItensNaTelaInicial) {
                    attCardapio = adicionarQuantidadeZeroProdutos(props)
                }

                if (estabelecimentoAtual?.id) {
                    removerProdutosDeOutrosEstabelecimentos(props)
                }

                if (estabelecimentoAtual?.taxaEntrega) {
                    endereco.taxaEntrega = estabelecimentoAtual.taxaEntrega
                }

                // setCardapio(attCardapio)
                props.carrinho = carrinho
                props.enderecoAtual = endereco
                props.cardapio = cardapio

                await calcularValorTotal(props)
            }
        } catch (error) {
            RegistrarLogErro(error, "retornoVerificarCardapio", props)
            alertStart(
                "Procure os desenvolvedores! Erro retornoVerificarCardapio: " + error.message,
                "error"
            )
        }
    }

    const adicionarEndereco = async ({ usuarioLogado, enderecoAtual }) => {
        var resp = null

        try {
            const enderecoBackUp = enderecoAtual
            const data = {
                idCliente: usuarioLogado.cliente.id,
                idEstabelecimento: estabelecimentoAtual ? estabelecimentoAtual.id : null,
                cep: enderecoBackUp.cep,
                uf: enderecoBackUp.uf,
                cidade: enderecoBackUp.cidade,
                bairro: enderecoBackUp.bairro,
                logradouro: enderecoBackUp.logradouro,
                numero: enderecoBackUp.numero,
                complemento: enderecoBackUp.complemento,
                referencia: enderecoBackUp.referencia,
                token: `${process.env.REACT_APP_CLIENTEFIEL_TOKEN}`,
            }

            if (enderecoBackUp.bairroEspecifico) {
                data.bairroEspecifico = 1
            } else {
                data.bairroEspecifico = 0
            }

            const response = await CadastrarEnderecoCliente(data, aplicativoDados)

            if (response.retornoErro) {
                //algum erro
                alertStart(response.mensagem, "error")
            } else {
                //tudo certo
                usuarioLogado.cliente.enderecos.push(response)
                setUsuarioLogado(usuarioLogado)
                enderecoAtual.id = response.id
                resp = response.id
            }
        } catch (error) {
            RegistrarLogErro(error, "CadastrarEnderecoCliente", { usuarioLogado, enderecoAtual })
            alertStart(
                "Procure os desenvolvedores! Erro CadastrarEnderecoCliente: " + error.message,
                "error"
            )
        }
        return resp
    }

    const removerProdutosDeOutrosEstabelecimentos = ({
        carrinho = usarCarrinho(),
        estabelecimentoAtual = usarEstabelecimentoAtual(),
    }) => {
        if (carrinho?.pedido?.itens?.length) {
            carrinho.pedido.itens.forEach((item, index) => {
                if (item.idEstabelecimento != estabelecimentoAtual.id) {
                    carrinho.pedido.itens.splice(index, 1)
                }
            })
        }
        return carrinho
    }

    const adicionarQuantidadeZeroProdutos = ({
        cardapio = usarCardapio(),
        carrinho = usarCarrinho(),
    }) => {
        if (carrinho.pedido?.itens?.length > 0) {
            cardapio?.categorias?.forEach(categoria => {
                categoria.produtos.forEach(produto => {
                    var temNoCarrinho = false
                    carrinho.pedido.itens.forEach((item, index) => {
                        if (item.produto.id == produto.id) {
                            produto.quantidade = item.quantidade
                            temNoCarrinho = true
                        }
                    })

                    if (temNoCarrinho == false) {
                        produto.quantidade = 0
                    }
                })
            })
        } else {
            cardapio?.categorias?.forEach(categoria => {
                categoria.produtos.forEach(produto => {
                    produto.quantidade = 0
                })
            })
        }
        return cardapio
    }

    const apartirDe = ({ cardapio = usarCardapio() }) => {
        cardapio?.categorias?.forEach(categoria => {
            categoria.produtos.forEach(produto => {
                produto.valorRealCalculado = calcularValorAPartirDe(produto)
                produto.valorDeCalculado = calcularValorAPartirDe(produto, true)
            })
        })
        return cardapio
    }

    function calcularValorAPartirDe(prod, valorPromocional = false) {
        try {
            const produto = JSON.parse(JSON.stringify(prod))
            if (valorPromocional) {
                produto.valor = produto.valorDe
            }

            var valorProdutoHistorico = produto.valor ? produto.valor : 0.0

            produto.opcoes.forEach(grupo => {
                // //console.log('Produto>', produto.nome)
                if (grupo.quantidadeMinima > 0) {
                    // //console.log('Grupo>', grupo.nome)
                    //considerar maior  valor - prioridade 1
                    const verificarMaiorValor =
                        grupo.opcoes.length > 0 ? grupo.opcoes[0].considerarMaiorValor : false
                    var maiorValorDoGrupo = 0

                    //considerar media dos valores - prioridade 2
                    const verificarMediaValor =
                        grupo.opcoes.length > 1 ? grupo.opcoes[0].considerarMedia : false
                    var valorTotalDasOpcoes = 0
                    var quantidadeDeOpcoes = 0

                    //console.log('verificarMaiorValor> ', verificarMaiorValor)
                    //console.log('verificarMediaValor> ', verificarMediaValor)

                    grupo.opcoes.sort(function (a, b) {
                        if (a.valor > b.valor) {
                            return 1
                        }
                        if (a.valor < b.valor) {
                            return -1
                        }
                        // a must be equal to b
                        return 0
                    })

                    var qtd = 0
                    grupo.opcoes.forEach(opcao => {
                        if (opcao.informativo) return

                        if (qtd < grupo.quantidadeMinima) {
                            qtd++

                            let quantidadeSelecionada =
                                grupo.adicional === 2 || grupo.adicional === 3
                                    ? grupo.quantidadeMinima
                                    : 1
                            if (quantidadeSelecionada > 1) {
                                qtd = grupo.quantidadeMinima
                            }

                            if (verificarMaiorValor && opcao.valor > maiorValorDoGrupo) {
                                maiorValorDoGrupo = opcao.valor
                            } else if (verificarMediaValor && valorTotalDasOpcoes >= 0) {
                                valorTotalDasOpcoes += opcao.valor * quantidadeSelecionada
                                quantidadeDeOpcoes += quantidadeSelecionada
                            }

                            if (
                                grupo.adicional === 0 &&
                                opcao.quantidade === 1 &&
                                opcao.valor > 0
                            ) {
                                valorProdutoHistorico =
                                    valorProdutoHistorico - produto.valor + opcao.valor
                            } else if (!verificarMaiorValor && !verificarMediaValor) {
                                //console.log('nao verificar maior nem media', qtd)
                                valorProdutoHistorico += opcao.valor * quantidadeSelecionada
                            }
                        }
                    })

                    if (verificarMaiorValor) {
                        valorProdutoHistorico += maiorValorDoGrupo
                    } else if (verificarMediaValor && valorTotalDasOpcoes >= 0) {
                        valorProdutoHistorico += valorTotalDasOpcoes / quantidadeDeOpcoes
                    }
                } else if (grupo.adicional === 0 && grupo?.opcoes[0]?.valor > 0) {
                    // //console.log('adicional igual 0 ', grupo)
                    valorProdutoHistorico += grupo.opcoes[0].valor
                    valorProdutoHistorico -= produto.valor ? produto.valor : 0.0
                }
            })

            return valorProdutoHistorico
        } catch (error) {
            //   RegistrarLogErro(error, "calcularValorAPartirDe", data)
            //   alertStart("Procure os desenvolvedores! Erro calcularValorAPartirDe: "+error.message, "error")
        }

        return prod.valor
    }

    return (
        <CalculosContext.Provider
            value={{
                calcularValorTotal,
                ObterCardapioCalculo,
                adicionarQuantidadeZeroProdutos,
                apartirDe,
                adicionarEndereco,
                usarCarrinho,
            }}
        >
            {children}
        </CalculosContext.Provider>
    )
}
